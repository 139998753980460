.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite !important;
}

.placeholder {
    display: inline-block;
    min-height: 0.8em;
    width: 150px;
    color: #5a5252;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.8;
    /* border-radius: 10px; */
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.5;
    }
}